import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useLocation } from "@reach/router";
// import { graphql } from "gatsby";
// import Header from '../component/layout/Header';
// import Body from '../component/layout/Body';
// import Footer from '../component/layout/Footer';
import Ticket from '../component/Ticket'
import { Provider as VideoplayerProvider } from '../context/VideoplayerContext';
import "../styles/styles.scss";

// markup
const IndexPage = () => {
  const location = useLocation();
  const [performanceID, setPerformanceID] = useState(""); // Performance
  useEffect(() => {
    let p;
    let t;
    if (queryString.parse(location.search)['p'] && queryString.parse(location.search)['t']) {
      // Check if ticket and performance query parameters exist in the URL
      p = queryString.parse(location.search)['p'];
      t = queryString.parse(location.search)['t'];
      // Save the values in the local storage in case if reload is necessary
      localStorage.setItem('ticket', `${t}`);
      localStorage.setItem('performance', `${p}`);
      setPerformanceID(p);
    } else {
      window.location.href = 'https://www.youngvic.org/';
    }
  }, [])
  return (
    // Blank page that will redirect to another URL. Each show will be displayed on a separate URL (e.g. https://yvt-rivver.netlify.app/pilot1)
    <VideoplayerProvider>
      {performanceID &&
        <Ticket performanceID={performanceID} />
      }
    </VideoplayerProvider>
  )

}
export default IndexPage;