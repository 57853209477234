import React, { useEffect, useContext } from 'react';
import { Context as VideoPlayerContext } from '../context/VideoplayerContext';
// Component that gets the performance and redirects to the show page
const Ticket = (props) => {
    const {
        state: {
            showName,
        },
        getPerformance,
    } = useContext(VideoPlayerContext);
    useEffect(() => {
        getPerformance(props.performanceID).then((response) => {
            if (response) {
                window.location.href = `/${response.name}`;
            } else {
                localStorage.removeItem('ticket');
                localStorage.removeItem('performance');
                window.location.href = `/404`;
            }
        })
    }, []);
    return (
        <div></div>
    )
}

export default Ticket;